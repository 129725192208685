import GATSBY_COMPILED_MDX from "/opt/build/repo/content/blog/angular-service-blir-ikke-singleton/index.mdx";
import React from 'react';
import {Link} from 'gatsby';
import {GatsbyImage} from "gatsby-plugin-image";
import MdxBlock from '../components/mdxBlock';
import Layout from '../components/layout';
import SEO from '../components/seo';
import * as styles from './blog.module.scss';
import {ArticleTag} from '../components/article-tag';
var BlogPostTemplate = function BlogPostTemplate(_ref) {
  var data = _ref.data, pageContext = _ref.pageContext, location = _ref.location, children = _ref.children;
  var post = data.mdx;
  var siteTitle = data.site.siteMetadata.title;
  var previous = pageContext.previous, next = pageContext.next;
  var timeToRead = Math.floor(post.fields.timeToRead.minutes);
  return React.createElement(Layout, {
    location: location,
    title: siteTitle
  }, React.createElement(SEO, {
    title: post.frontmatter.title,
    description: post.frontmatter.description || post.excerpt
  }), React.createElement("article", null, React.createElement("header", {
    className: styles.header
  }, React.createElement("div", {
    className: styles.heading_container
  }, React.createElement("h1", null, post.frontmatter.title)), React.createElement("div", {
    className: styles.hero_img
  }, post.frontmatter.heroimage ? React.createElement(GatsbyImage, {
    image: post.frontmatter.heroimage.childImageSharp.gatsbyImageData,
    alt: "post hero image"
  }) : ''), React.createElement("div", {
    className: styles.article__tags
  }, post.frontmatter.tags.map(function (tag) {
    return React.createElement(ArticleTag, {
      tag: tag,
      key: tag
    });
  })), React.createElement("div", {
    className: styles.article__datetime
  }, React.createElement("time", {
    dateTime: post.frontmatter.date
  }, post.frontmatter.date, ' ', React.createElement("em", null, "(oppdatert ", post.frontmatter.updated, ")")), React.createElement("div", null, "ca. ", timeToRead, " minutt", timeToRead > 1 ? 'er' : '', " med lesestoff"))), React.createElement("div", {
    className: "blog-content__container"
  }, React.createElement(MdxBlock, null, children)), React.createElement("nav", null, React.createElement("ul", {
    style: {
      display: "flex",
      flexWrap: "wrap",
      justifyContent: "space-between",
      listStyle: "none",
      padding: 0
    }
  }, React.createElement("li", null, previous && React.createElement(Link, {
    to: "/" + previous.frontmatter.slug,
    rel: "prev"
  }, "\u2190 ", previous.frontmatter.title)), React.createElement("li", null, next && React.createElement(Link, {
    to: "/" + next.frontmatter.slug,
    rel: "next"
  }, next.frontmatter.title, " \u2192"))))));
};
BlogPostTemplate
export default function GatsbyMDXWrapper(props) {
  return React.createElement(BlogPostTemplate, props, React.createElement(GATSBY_COMPILED_MDX, props));
}
var pageQuery = "3539877212";
